// AppleCatcher.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './AppleCatcher.css';
import UniverseData from '../../UniverseData';

const AppleCatcher = ({ onGameOver }) => {
  const mountedRef = useRef(false);

  const [gameState, setGameState] = useState({
    score: 0,
    time: 10,
    apples: [],
    playerPosition: 50,
    gameStarted: false,
    showResult: false
  });

  const [scoreAnimations, setScoreAnimations] = useState([]);
  const timerRef = useRef(null);
  const gameLoopRef = useRef(null);
  const lastUpdateRef = useRef(0);
  const gameEndedRef = useRef(false);

  const gameLoop = useCallback((timestamp) => {
    if (!mountedRef.current) return;
    if (timestamp - lastUpdateRef.current > 18) {
      lastUpdateRef.current = timestamp;

      setGameState((prev) => {
        if (!prev.gameStarted) return prev;

        let { apples, score } = prev;

        // Move apples
        apples = apples.map((apple) => {
          if (apple.hasCollided) return apple;
          return { ...apple, y: apple.y + 1 };
        });

        // Small chance to spawn new apple
        if (Math.random() < 0.05 && apples.length < 40) {
          apples.push({
            id: Date.now(),
            x: Math.random() * 100,
            y: 0,
            type: Math.random() > 0.5 ? 'good' : 'rotten',
            hasCollided: false
          });
        }

        // Collisions
        const catcherWidth = 25;
        const leftEdge = prev.playerPosition - catcherWidth / 2;
        const rightEdge = prev.playerPosition + catcherWidth / 2;
        const catcherTop = 80;
        const nextApples = [];

        for (const apple of apples) {
          if (apple.y > 100) {
            continue; 
          }
          if (apple.hasCollided) {
            continue;
          }

          const appleBottom = apple.y + 5;
          if (
            appleBottom >= catcherTop &&
            appleBottom < catcherTop + 5 &&
            apple.x >= leftEdge &&
            apple.x <= rightEdge
          ) {
            apple.hasCollided = true;
            const points = apple.type === 'good' ? 500 : -5000;
            score = Math.max(0, score + points);

            // Log the collisions to UniverseData
            UniverseData.updateGameSession({
              type: apple.type,
              score: points,
              position: { x: apple.x, y: apple.y }
            });

            // Animate the floating score
            const animId = `anim_${apple.id}_${Math.random().toString(36).substr(2, 9)}`;
            setScoreAnimations((prevAnims) => [
              ...prevAnims,
              { id: animId, x: apple.x, y: catcherTop - 15, score: points }
            ]);
            setTimeout(() => {
              setScoreAnimations((prevAnims) =>
                prevAnims.filter((anim) => anim.id !== animId)
              );
            }, 1000);
          } else {
            nextApples.push(apple);
          }
        }

        return { ...prev, apples: nextApples, score };
      });
    }
    gameLoopRef.current = requestAnimationFrame(gameLoop);
  }, []);

  const startGame = useCallback(async () => {
    if (!mountedRef.current) return;

    // check attempts from UniverseData
    const currentData = UniverseData.getCurrentData();
    if (!currentData?.bombs) {
      console.log('[AppleCatcher] No bombs data => cannot start');
      return;
    }
    let { attempts, last_activity } = currentData.bombs;
    attempts = attempts ?? 3;

    // if 24h passed, reset to 3
    if (last_activity) {
      const now = Date.now();
      const lastTime = new Date(last_activity).getTime();
      const diffHours = (now - lastTime) / (1000 * 60 * 60);
      if (diffHours >= 24) {
        attempts = 3;
      }
    }

    if (attempts <= 0) {
      console.log('[AppleCatcher] No attempts left => cannot start');
      return;
    }

    // "start_game" => server side
    try {
      const ok = UniverseData.handleBomb('start_game');
      if (!ok) {
        console.log('[AppleCatcher] handleBomb => not OK => no attempts?');
        return;
      }
      // Sync so the server sees we started
      await UniverseData.syncActions();

      setGameState((prev) => ({
        ...prev,
        gameStarted: true,
        time: 10,
        score: 0,
        apples: [],
        showResult: false
      }));
      gameEndedRef.current = false;
    } catch (error) {
      console.error('[AppleCatcher] Error starting game:', error);
    }
  }, []);

  const endGame = useCallback(async () => {
    if (!mountedRef.current) return;
    if (gameEndedRef.current) return;
    gameEndedRef.current = true;

    setGameState((prev) => ({ ...prev, gameStarted: false, showResult: true }));

    // Store final score for bombs session
    UniverseData.setGameSessionScore(gameState.score);
    UniverseData.handleBomb('end_game');
    // Final sync => server sees final score
    await UniverseData.syncActions(null, true);

    clearInterval(timerRef.current);
    cancelAnimationFrame(gameLoopRef.current);

    console.log('[AppleCatcher] endGame => final local score:', gameState.score);
  }, [gameState.score]);

  // If time=0 => end
  useEffect(() => {
    if (!mountedRef.current) return;

    if (gameState.gameStarted && gameState.time > 0) {
      timerRef.current = setInterval(() => {
        setGameState((prev) => ({ ...prev, time: prev.time - 1 }));
      }, 1000);

      gameLoopRef.current = requestAnimationFrame(gameLoop);

      return () => {
        clearInterval(timerRef.current);
        cancelAnimationFrame(gameLoopRef.current);
      };
    } else if (gameState.gameStarted && gameState.time === 0) {
      endGame();
    }
  }, [gameState.gameStarted, gameState.time, gameLoop, endGame]);

  // move catcher with touch
  const handleTouchMove = useCallback(
    (e) => {
      e.preventDefault();
      if (!mountedRef.current) return;
      if (!gameState.gameStarted) return;

      const touch = e.touches[0];
      const screenWidth = window.innerWidth;
      const catcherWidthPercent = (80 / screenWidth) * 100;
      const halfCatcher = catcherWidthPercent / 2;

      let newPos = (touch.clientX / screenWidth) * 100;
      newPos = Math.max(halfCatcher, Math.min(100 - halfCatcher, newPos));

      setGameState((prev) => ({ ...prev, playerPosition: newPos }));
    },
    [gameState.gameStarted]
  );

  // user sees final screen => press OK => pass final score to parent
  const handleResultClose = () => {
    setGameState((prev) => ({ ...prev, showResult: false }));
    onGameOver?.(gameState.score);
  };

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, []);

  return (
    <div className="apple-catcher" onTouchMove={handleTouchMove}>
      <div className="game-info">
        <span>Score: {gameState.score}</span>
        <span>Time: {gameState.time}</span>
      </div>

      {!gameState.gameStarted && !gameState.showResult && (
        <button className="start-button" onClick={startGame}>
          Start Game
        </button>
      )}

      <div className="game-area">
        {gameState.apples.map((apple) => (
          <div
            key={apple.id}
            className={`neon-apple ${apple.type === 'good' ? 'good-apple' : 'rotten-apple'}`}
            style={{
              left: `${apple.x}%`,
              top: `${apple.y}%`,
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              backgroundColor: apple.type === 'good' ? 'green' : 'brown',
              position: 'absolute'
            }}
          />
        ))}

        {scoreAnimations.map((anim) => (
          <div
            key={anim.id}
            className={`score-animation ${anim.score > 0 ? 'positive' : 'negative'}`}
            style={{ left: `${anim.x}%`, top: `${anim.y}%` }}
            ref={(el) => {
              if (el) setTimeout(() => el.classList.add('active'), 50);
            }}
          >
            {anim.score > 0 ? `+${anim.score}` : anim.score}
          </div>
        ))}

        <div
          className="neon-catcher"
          style={{
            left: `${gameState.playerPosition}%`,
            width: '80px',
            height: '30px',
            backgroundColor: 'greenyellow',
            position: 'absolute',
            bottom: '20%',
            borderRadius: '13% 13% 100% 100%'
          }}
        />
      </div>

      {gameState.showResult && (
        <div className="result-modal">
          <div className="result-content">
            <h2>END GAME</h2>
            <p>Score: {gameState.score}</p>
            <button onClick={handleResultClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppleCatcher;
