import React, { useState, useRef, useEffect } from 'react';
import '../css.components/tutorial.css';  // your CSS

const Tutorial = ({ onClose }) => {
  const [step, setStep] = useState(0);
  const [lang, setLang] = useState('en');
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });

  // Refs to your UI elements
  const energyRef = useRef(null);
  const walletRef = useRef(null);
  const exchangeRef = useRef(null);
  const giftboxRef = useRef(null);
  const miniGamesRef = useRef(null);
  const referralRef = useRef(null);
  const leaderboardRef = useRef(null);

  // Map string -> actual React ref
  const refMap = {
    energyRef,
    walletRef,
    exchangeRef,
    giftboxRef,
    miniGamesRef,
    referralRef,
    leaderboardRef,
  };

  // EN steps
  const stepsEn = [
    {
      title: 'Welcome to the game!',
      description:
        "Here you can manage your universe and improve it by tapping on the screen. Let's start with the basics.",
      info: "This is an introduction to the game, explaining that you can manage your universe by tapping the screen and upgrading it.",
      targetRef: null,
      position: { top: 10, left: 30, width: 20, height: 10 },
    },
    {
      title: 'Referral Zone',
      description:
        'Invite friends to the game, earn bonuses, and expand your universe.',
      info: 'You will learn how the referral system works; by inviting friends, you earn bonuses based on their activity.',
      targetRef: 'referralRef',
      position: { top: 100, left: 1, width: 18, height: 7 },
    },
    {
      title: 'Wallet',
      description:
        'Your wallet stores all your resources that can be used to develop your universe.',
      info: "Here you can see the resources you've collected and use them for further enhancements.",
      targetRef: 'walletRef',
      position: { top: 100, left: 21, width: 18, height: 7 },
    },
    {
      title: 'Mini-Games',
      description:
        'In mini-games, you can earn additional resources and bonuses. Try a few games!',
      info: 'Mini-games allow you to earn extra resources that you can spend on your universe improvements.',
      targetRef: 'miniGamesRef',
      position: { top: 100, left: 41, width: 18, height: 7 },
    },
    {
      title: 'Exchange',
      description:
        'The Exchange is where you can trade resources for other valuable items and opportunities.',
      info: 'You can trade your in-game resources for other items that speed up your progress.',
      targetRef: 'exchangeRef',
      position: { top: 100, left: 61, width: 18, height: 7 },
    },
    {
      title: 'Upgrades',
      description:
        'Use the collected resources to upgrade your universe and make it more powerful.',
      info: 'Upgrades let you make your universe even more beautiful and powerful.',
      targetRef: 'exchangeRef',
      position: { top: 100, left: 81, width: 18, height: 7 },
    },
    {
      title: 'Dailies',
      description:
        'Receive a daily bonus for collecting a reward in a streak every day.',
      info: 'Each day, the bonus increases as long as you continue the streak without missing a day.',
      targetRef: 'giftboxRef',
      position: { top: 37.5, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Giftbox',
      description:
        'Obtain giftboxes that may contain rare resources and bonuses for upgrades.',
      info: 'Giftboxes provide rare resources and bonuses to help you progress further.',
      targetRef: 'giftboxRef',
      position: { top: 30, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Tasks',
      description:
        'Complete tasks to earn extra resources and bonuses for upgrades.',
      info: 'Tasks are additional goals that allow you to earn resources and bonuses.',
      targetRef: 'energyRef',
      position: { top: 23.5, left: 88, width: 18, height: 7 },
    },
    {
      title: 'Notifications',
      description:
        'Keep track of important events in the game through notifications so you do not miss anything.',
      info: 'Notifications keep you informed about significant events, new opportunities, or achievements.',
      targetRef: 'energyRef',
      position: { top: 11, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Leaderboard & Profile',
      description:
        'Track your progress and compete with other players on the leaderboard.',
      info: "The leaderboard lets you see your progress and compare it with other players' achievements.",
      targetRef: 'leaderboardRef',
      position: { top: 0, left: 0, width: 13, height: 6 },
    },
    {
      title: 'Upgrade Energy',
      description:
        'Use energy for upgrades to make your universe more beautiful and powerful.',
      info: 'Energy is used for various upgrades that boost your universe.',
      targetRef: 'energyRef',
      position: { top: 1.9, left: 110, width: 15, height: 2.5 },
    },
    {
      title: 'Total Coin Amount',
      description:
        'Coins allow you to perform upgrades and grow your universe. Keep track of them!',
      info: 'Coins are the main resource you use for upgrades and development.',
      targetRef: 'energyRef',
      position: { top: 13, left: 36, width: 30, height: 4 },
    },
    // EWE (next-to-last step)
    {
      title: 'EWE Dimension',
      description:
        'Swipe to the left for the EWE dimension, which passively farms currency.',
      info: 'This dimension keeps earning currency while you focus on other tasks.',
      targetRef: null,
      position: { top: 60, left: 30, width: 20, height: 10 },
    },
    // ECI (last step)
    {
      title: 'ECI Dimension',
      description:
        'Swipe to the right for the ECI dimension, part of cleaning the environment, currently under development.',
      info: 'Once fully developed, it will help maintain a cleaner environment in your universe.',
      targetRef: null,
      position: { top: 70, left: 30, width: 20, height: 10 },
    },
  ];

  // RU steps
  const stepsRu = [
    {
      title: 'Добро пожаловать в игру!',
      description:
        'Здесь вы можете управлять своей вселенной и улучшать её, нажимая на экран. Начнем с основы.',
      info: 'Это введение в игру, где объясняется, что вы можете управлять своей вселенной, нажимая на экран и улучшать её.',
      targetRef: null,
      position: { top: 10, left: 30, width: 20, height: 10 },
    },
    {
      title: 'Реферальная зона',
      description:
        'Приглашайте друзей в игру, получайте бонусы и расширяйте свою вселенную.',
      info: 'Здесь вы узнаете, как работает реферальная система; пригласив друзей, вы получите бонусы за их активность.',
      targetRef: 'referralRef',
      position: { top: 100, left: 1, width: 18, height: 7 },
    },
    {
      title: 'Кошелек',
      description:
        'В вашем кошельке хранятся все ваши ресурсы, которые можно использовать для развития вселенной.',
      info: 'Здесь вы можете увидеть все свои собранные ресурсы и использовать их для дальнейших улучшений.',
      targetRef: 'walletRef',
      position: { top: 100, left: 21, width: 18, height: 7 },
    },
    {
      title: 'Мини-игры',
      description:
        'В мини-играх можно зарабатывать дополнительные ресурсы и бонусы. Попробуйте пройти несколько игр!',
      info: 'Мини-игры позволяют вам зарабатывать дополнительные ресурсы, которые можно потратить на улучшения вашей вселенной.',
      targetRef: 'miniGamesRef',
      position: { top: 100, left: 41, width: 18, height: 7 },
    },
    {
      title: 'Биржа',
      description:
        'Биржа — это место, где вы можете обменивать ресурсы на другие ценные предметы и возможности.',
      info: 'На бирже вы можете обменять ресурсы, полученные в игре, на другие предметы, которые ускорят ваш прогресс.',
      targetRef: 'exchangeRef',
      position: { top: 100, left: 61, width: 18, height: 7 },
    },
    {
      title: 'Улучшения',
      description:
        'Используйте собранные ресурсы для улучшения своей вселенной, чтобы она стала более мощной.',
      info: 'Улучшения позволят вам сделать вашу вселенную еще более красивой и мощной.',
      targetRef: 'exchangeRef',
      position: { top: 100, left: 81, width: 18, height: 7 },
    },
    {
      title: 'Ежедневки',
      description:
        'Receive a daily bonus for collecting a reward in a strike every day.',
      info: 'Each day, the bonus will increase as you have the strike without missing a day.',
      targetRef: 'giftboxRef',
      position: { top: 37.5, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Гифтбокс',
      description:
        'Получайте гифтбоксы, которые могут содержать редкие ресурсы и бонусы для улучшений.',
      info: 'Гифтбоксы предоставляют редкие ресурсы и бонусы, которые помогут вам в дальнейшем развитии.',
      targetRef: 'giftboxRef',
      position: { top: 30, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Таски',
      description:
        'Выполняйте задачи, чтобы получать дополнительные ресурсы и бонусы для улучшений.',
      info: 'Задачи (таски) — это дополнительные задания, которые позволяют вам заработать ресурсы и бонусы.',
      targetRef: 'energyRef',
      position: { top: 23.5, left: 88, width: 18, height: 7 },
    },
    {
      title: 'Уведомления',
      description:
        'Следите за важными событиями в игре через уведомления, чтобы не пропустить что-то важное.',
      info: 'Уведомления информируют вас о важных событиях в игре, таких как новые возможности или достижения.',
      targetRef: 'energyRef',
      position: { top: 11, left: 90, width: 18, height: 7 },
    },
    {
      title: 'Лидерборд & Профиль',
      description:
        'Следите за своим прогрессом и соревнуйтесь с другими игроками на лидерборде.',
      info: 'Лидерборд позволяет вам отслеживать свой прогресс и сравнивать его с достижениями других игроков.',
      targetRef: 'leaderboardRef',
      position: { top: 0, left: 0, width: 13, height: 6 },
    },
    {
      title: 'Энергия улучшения',
      description:
        'Используйте энергию для улучшений, чтобы ваша вселенная стала более красивой и мощной.',
      info: 'Энергия используется для различных улучшений, которые увеличивают вашу вселенную.',
      targetRef: 'energyRef',
      position: { top: 1.9, left: 110, width: 15, height: 2.5 },
    },
    {
      title: 'Общее количество монет',
      description:
        'Монеты позволяют вам выполнять улучшения и развивать свою вселенную. Отслеживайте их количество.',
      info: 'Монеты — это основной ресурс, который вы будете использовать для улучшений и развития вселенной.',
      targetRef: 'energyRef',
      position: { top: 13, left: 36, width: 30, height: 4 },
    },
    // EWE
    {
      title: 'Измерение EWE',
      description:
        'Проведите пальцем влево, чтобы перейти в измерение EWE, где пассивно добывается валюта.',
      info: 'Вы можете собирать валюту, пока сосредоточены на других задачах.',
      targetRef: null,
      position: { top: 60, left: 30, width: 20, height: 10 },
    },
    // ECI
    {
      title: 'Измерение ECI',
      description:
        'Проведите пальцем вправо, чтобы перейти в измерение ECI — часть очистки окружающей среды, которая сейчас в разработке.',
      info: 'После полной разработки это измерение будет помогать поддерживать чистоту во вселенной.',
      targetRef: null,
      position: { top: 70, left: 30, width: 20, height: 10 },
    },
  ];

  const buttonTexts = {
    en: {
      next: 'Next',
      close: "Got it, let's continue!",
    },
    ru: {
      next: 'Далее',
      close: 'Понял, продолжим!',
    },
  };

  const steps = lang === 'en' ? stepsEn : stepsRu;

  // Move highlight box
  const updateBoxPosition = (elementRef, position) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const boxTop = (position.top / 100) * screenHeight;
    const boxLeft = (position.left / 100) * screenWidth;
    const boxWidth = (position.width / 100) * screenWidth;
    const boxHeight = (position.height / 100) * screenHeight;

    const constrainedTop = Math.max(0, Math.min(boxTop, screenHeight - boxHeight));
    const constrainedLeft = Math.max(0, Math.min(boxLeft, screenWidth - boxWidth));
    const constrainedWidth = Math.min(boxWidth, screenWidth - constrainedLeft);
    const constrainedHeight = Math.min(boxHeight, screenHeight - constrainedTop);

    setBoxPosition({
      top: constrainedTop,
      left: constrainedLeft,
      width: constrainedWidth,
      height: constrainedHeight,
    });
  };

  useEffect(() => {
    const { targetRef, position } = steps[step];
    const realRef = targetRef ? refMap[targetRef] : null;
    if (position) {
      updateBoxPosition(realRef, position);
    }
  }, [step, steps]);

  // Navigation
  const handleNextStep = () => {
    if (step < steps.length - 1) setStep(step + 1);
  };
  const handleClose = () => {
    onClose();
  };

  // Are we on the last two steps (where we show arrows)?
  const showSwipeArrows = step >= steps.length - 2;

  return (
    <>
      {/* TUTORIAL OVERLAY & CONTAINER */}
      <div className="tutorial-overlay">
        <div className="tutorial-container">
          {/* Language Dropdown */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="langSelect" style={{ marginRight: '5px' }}>
              Language:
            </label>
            <select
              id="langSelect"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              <option value="en">EN</option>
              <option value="ru">RU</option>
            </select>
          </div>

          {/* Step Content */}
          <h2>{steps[step].title}</h2>
          <p>{steps[step].description}</p>
          <p><i>{steps[step].info}</i></p>

          {/* Next / Close Button */}
          {step < steps.length - 1 ? (
            <button onClick={handleNextStep}>
              {buttonTexts[lang].next}
            </button>
          ) : (
            <button onClick={handleClose}>
              {buttonTexts[lang].close}
            </button>
          )}
        </div>

        {/* Highlight Box (if there's a targetRef) */}
        {steps[step].targetRef && (
          <div
            className="tutorial-box"
            style={{
              top: boxPosition.top + 'px',
              left: boxPosition.left + 'px',
              width: boxPosition.width + 'px',
              height: boxPosition.height + 'px',
              transition: 'all 0.5s ease',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>

      {/* SWIPE ARROWS UNDER THE TUTORIAL BOX */}
      {showSwipeArrows && (
        <div className="swipe-arrows-container">
          <div className="swipe-arrow-left" />
          <div className="swipe-arrow-right" />
        </div>
      )}
    </>
  );
};

export default Tutorial;
