import React, { useEffect, useState } from 'react';

function UserLogo({ defaultLogo }) {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    function getProfilePhoto() {
      try {
        const user = window.Telegram?.WebApp?.initDataUnsafe?.user;
        if (user && user.photo_url) {
          setLogo(user.photo_url);
        } else {
          console.log('No profile photo available in WebApp');
          setLogo(defaultLogo);
        }
      } catch (error) {
        console.error('Error getting profile photo:', error);
        setLogo(defaultLogo);
      }
    }
    
    getProfilePhoto();
  }, [defaultLogo]);

  return <img src={logo || defaultLogo} className="logo" alt="User Logo" />;
}

export default UserLogo;
