// BoostTab.js
import React, { useState, useEffect } from 'react';
import AppleCatcher from '../games/apple-game/AppleCatcher';
import PurblePairs from '../games/purble-game/PurblePairs';
import UniverseData from '../UniverseData';
import '../css.components/BoostTab.css';
import bombsImg from '../../public/Bombs.jpg';
import pairsImg from '../../public/PurblePairs.png';
import soon from '../../public/Soon.gif';

const formatTimeLeft = (hours) => {
  if (hours < 1) {
    const minutes = Math.ceil(hours * 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }
  return `${Math.ceil(hours)} hour${Math.ceil(hours) !== 1 ? 's' : ''}`;
};

const games = [
  { 
    name: 'BOMBS!', 
    image: bombsImg, 
    id: 'BOMBS', 
    hasTimeLimit: true 
  },
  // Replaced Game 2 with PurblePairs
  { 
    name: 'Purble Pairs', 
    image: pairsImg, // Change to your desired image if available
    id: 'PURBLE_PAIRS' 
  },
  { name: 'Game 3', image: null, id: 'GAME_3' },
  { name: 'Game 4', image: null, id: 'GAME_4' },
  { name: 'Game 5', image: null, id: 'GAME_5' },
  { name: 'Game 6', image: null, id: 'GAME_6' },
  { name: 'Game 7', image: null, id: 'GAME_7' },
  { name: 'Game 8', image: null, id: 'GAME_8' },
  { name: 'Game 9', image: null, id: 'GAME_9' },
  { name: 'Game 10', image: null, id: 'GAME_10' },
  { name: 'Game 11', image: null, id: 'GAME_11' },
  { name: 'Game 12', image: null, id: 'GAME_12' },
  { name: 'Game 13', image: null, id: 'GAME_13' },
  { name: 'Game 14', image: null, id: 'GAME_14' },
  { name: 'Game 15', image: null, id: 'GAME_15' },
  { name: 'Game 16', image: null, id: 'GAME_16' },
  { name: 'Game 17', image: null, id: 'GAME_17' },
  { name: 'Game 18', image: null, id: 'GAME_18' },
  { name: 'Game 19', image: null, id: 'GAME_19' },
  { name: 'Game 20', image: null, id: 'GAME_20' },
];

const BoostTab = ({ onBombGameEnded }) => {
  const [activeGame, setActiveGame] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationTimeout, setNotificationTimeout] = useState(null);

  useEffect(() => {
    return () => {
      if (notificationTimeout) clearTimeout(notificationTimeout);
    };
  }, [notificationTimeout]);

  const showNotificationMessage = (message) => {
    if (notificationTimeout) clearTimeout(notificationTimeout);
    setNotificationMessage(message);
    setShowNotification(true);
    const timeout = setTimeout(() => setShowNotification(false), 3000);
    setNotificationTimeout(timeout);
  };

  const getBombsData = () => {
    const currentData = UniverseData.getCurrentData();
    if (!currentData || !currentData.bombs) {
      return { attempts: 3, last_activity: null };
    }
    return {
      attempts: currentData.bombs.attempts ?? 3,
      last_activity: currentData.bombs.last_activity
    };
  };

  const getAttemptsLeft = () => {
    const { attempts, last_activity } = getBombsData();
    if (!last_activity) return attempts;

    const now = Date.now();
    const lastTime = new Date(last_activity).getTime();
    const diffHours = (now - lastTime) / (1000 * 60 * 60);
    if (diffHours >= 24) return 3;

    return attempts;
  };

  const handleGameClick = (e, game) => {
    e.preventDefault();
    e.stopPropagation();

    if (!game.image) {
      showNotificationMessage("This game is still in development. Stay tuned!");
      return;
    }

    if (game.id === 'BOMBS') {
      const attemptsLeft = getAttemptsLeft();
      if (attemptsLeft <= 0) {
        // check time left
        const { last_activity } = getBombsData();
        if (last_activity) {
          const now = Date.now();
          const lastTime = new Date(last_activity).getTime();
          const diffHours = (now - lastTime) / (1000 * 60 * 60);
          const hoursRemaining = 24 - diffHours;
          if (hoursRemaining > 0) {
            showNotificationMessage(
              `You can't replay yet! Please wait ${formatTimeLeft(hoursRemaining)}.`
            );
            return;
          }
        }
      }
    }

    setActiveGame(game.id);
  };

  // Called when AppleCatcher ends => notifies parent if needed
  const handleBombsGameOver = (finalScore) => {
    setActiveGame(null);
    if (onBombGameEnded) {
      onBombGameEnded(finalScore);
    }
  };

  // Called when PurblePairs ends => just reset active game
  const handlePurblePairsGameOver = () => {
    setActiveGame(null);
  };

  return (
    <div className="boost">
      {showNotification && (
        <div className="notification">
          {notificationMessage}
        </div>
      )}

      {!activeGame ? (
        <div className="game-selection">
          <h3 className="hov">Select a Mini-Game</h3>
          <div className="game-cards">
            {games.map((game, index) => (
              <div
                key={index}
                className="game-card"
                onClick={(e) => handleGameClick(e, game)}
              >
                {game.image ? (
                  <div className="game-card-content">
                    <img src={game.image} alt={game.name} className="game-image" />
                    <p className="game-title">{game.name}</p>
                    {game.hasTimeLimit && game.id === 'BOMBS' && (
                      <div className="attempts-counter">
                        Attempts: {getAttemptsLeft()}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="game-card-content soon-content">
                    <img src={soon} alt="Coming Soon" className="soon-image" />
                    <p className="game-title">Soon</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="game-container">
          {activeGame === 'BOMBS' && (
            <AppleCatcher onGameOver={handleBombsGameOver} />
          )}
          {activeGame === 'PURBLE_PAIRS' && (
            <PurblePairs onGameOver={handlePurblePairsGameOver} />
          )}
          {/* Add more minigames here as they become available */}
        </div>
      )}
    </div>
  );
};

export default BoostTab;
