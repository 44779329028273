import React, { useState } from 'react';
import '../css.components/GiftTab.css';

// Keep original image imports
import donutBoxImage from '../../public/donut-box.png';
import goldenDonutImage from '../../public/golden-donut.png';

const rarityColors = {
  common: '#8f8f8f',
  uncommon: '#33ff33',
  rare: '#0066ff',
  epic: '#9f4aff',
  legendary: '#ffcc00',
};

const normalPool = [
  { name: 'Coin 5',   amount: 5,   currency: 'COIN', rarity: 'common'   },
  { name: 'Coin 10',  amount: 10,  currency: 'COIN', rarity: 'common'   },
  { name: 'Coin 20',  amount: 20,  currency: 'COIN', rarity: 'common'   },
  { name: 'Coin 50',  amount: 50,  currency: 'COIN', rarity: 'uncommon' },
  { name: 'Coin 100', amount: 100, currency: 'COIN', rarity: 'uncommon' },
  { name: 'Coin 200', amount: 200, currency: 'COIN', rarity: 'rare'     },
  { name: 'Coin 300', amount: 300, currency: 'COIN', rarity: 'rare'     },
  { name: 'Coin 500', amount: 500, currency: 'COIN', rarity: 'epic'     },
  { name: 'Gem 50',   amount: 50,  currency: 'GEM',  rarity: 'epic'     },
];

const nftItem = {
  name: 'NFT!',
  amount: 1,
  currency: 'NFT',
  rarity: 'legendary',
};

function GiftTab() {
  const [hasFreeSpin, setHasFreeSpin] = useState(true);
  const [isGolden, setIsGolden] = useState(false);
  const [multiplier, setMultiplier] = useState(1);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [finalPrize, setFinalPrize] = useState(null);
  const [spool, setSpool] = useState([]);
  const [spinOffset, setSpinOffset] = useState(0);

  function selectPrize(isGoldenSpin) {
    if (!isGoldenSpin) {
      return { ...normalPool[Math.floor(Math.random() * normalPool.length)] };
    }
    return Math.random() < 0.05 ? { ...nftItem } : { ...normalPool[Math.floor(Math.random() * normalPool.length)] };
  }

  function generateSpool(finalPrize, isGoldenSpin) {
    const items = [];
    const spoolLength = 100;
    
    // Fill the spool
    for (let i = 0; i < spoolLength; i++) {
      if (isGoldenSpin && Math.random() < 0.1) {
        items.push({ ...nftItem });
      } else {
        items.push({ ...normalPool[Math.floor(Math.random() * normalPool.length)] });
      }
    }

    // Place the winning prize near the end
    const winningPosition = Math.floor(spoolLength * 0.85);
    items[winningPosition] = finalPrize;
    
    return items;
  }

  const handleSpin = () => {
    if (isSpinning) return;

    setIsSpinning(true);
    setShowResult(false);
    setSpinOffset(0);

    // Select winning prize
    const prize = selectPrize(isGolden);
    setFinalPrize(prize);

    // Generate initial spool position
    const newSpool = generateSpool(prize, isGolden);
    setSpool(newSpool);

    // Start spinning animation
    requestAnimationFrame(() => {
      setSpinOffset(0);
      requestAnimationFrame(() => {
        // Move to final position
        setSpinOffset(-7200); // Calibrated spinning distance
      });
    });

    // End spinning after animation
    setTimeout(() => {
      setIsSpinning(false);
      setShowResult(true);

      if (hasFreeSpin) {
        setHasFreeSpin(false);
        setIsGolden(true);
      }
    }, 4000);
  };

  const toggleMultiplier = (val) => {
    setMultiplier(prev => prev === val ? 1 : val);
  };

  return (
    <div className="gift-tab-container">
      <h2 className="gift-title">
        {isGolden ? 'Golden Spin Box' : 'Daily Free Spin'}
      </h2>

      <div className="donut-box-wrapper">
        <img
          src={isGolden ? goldenDonutImage : donutBoxImage}
          alt="Donut Box"
          className="donut-box"
        />
      </div>

      <div className="csgo-roller-window">
        <div 
          className="csgo-roller"
          style={{
            transform: `translateX(${spinOffset}px)`,
            transition: isSpinning ? 'transform 4s cubic-bezier(0.15, 0.85, 0.35, 1)' : 'none'
          }}
        >
          {spool.map((item, idx) => (
            <div
              key={idx}
              className="csgo-prize"
              style={{
                borderColor: rarityColors[item.rarity]
              }}
            >
              <p style={{ color: rarityColors[item.rarity] }}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
        <div className="csgo-roller-highlight"></div>
      </div>

      {!isGolden && hasFreeSpin ? (
        <button 
          className="spin-btn"
          onClick={handleSpin}
          disabled={isSpinning}
        >
          {isSpinning ? 'Spinning...' : 'Free Spin!'}
        </button>
      ) : (
        <div className="golden-controls">
          <button
            className="spin-btn"
            onClick={handleSpin}
            disabled={isSpinning}
          >
            {isSpinning ? 'Spinning...' : 'Golden Spin!'}
          </button>
          
          <div className="multiplier-choice">
            <button
              className={multiplier === 2 ? 'active' : ''}
              onClick={() => toggleMultiplier(2)}
            >
              x2
            </button>
            <button
              className={multiplier === 3 ? 'active' : ''}
              onClick={() => toggleMultiplier(3)}
            >
              x3
            </button>
          </div>
        </div>
      )}

      {showResult && finalPrize && (
        <div className="prize-result">
          <h3 style={{ color: rarityColors[finalPrize.rarity] }}>
            You won: {finalPrize.name}!
          </h3>
          <p>
            {multiplier > 1 ? 
              `${finalPrize.amount} x${multiplier} = ${finalPrize.amount * multiplier} ${finalPrize.currency}` :
              `${finalPrize.amount} ${finalPrize.currency}`
            }
          </p>
        </div>
      )}
    </div>
  );
}

export default GiftTab;