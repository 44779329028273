// EatsApp.js
import React, { useState, useEffect, useRef } from 'react';
import './EatsApp.css';

import tapMessages from './tapMessages'; // 300+ messages in a separate file

// ---- Normal Imports ----
import ReferralShare from './components/ReferralShare';
import ProgressBar from './components/ProgressBar';
import logo from '../public/logo.jpg';
import 'react-circular-progressbar/dist/styles.css';
import NotificationIcon from './components/Notifications';
import clickerImage from '../public/EWI.png';
import Ref from '../public/ref.png';
import Games from '../public/games.png';
import Upgrade from '../public/upgrade.png';
import Wallet from '../public/wallet.png';
import Birge from '../public/birge.png';
import Tasks from '../public/tasks.png';
import Background from './components/bg';
import UserLogo from './components/UserLogo';
import TasksTab from './components/TasksTab';
import Leaderboard from './components/leaderboard';
import GiftTab from './components/GiftTab';
import DailyBonus from './components/DailyBonus';
import Energ from '../public/en.gif';
import DailyClaimIcon from '../public/calendar.gif';
import Tutorial from './components/tutorial';
import GiftBoxIcon from '../public/box.gif';
import UpgradeTab from './components/UpgradeTab';
import BoostTab from './components/BoostTab';

// UniverseData holds all local state & does server sync
import UniverseData from './UniverseData';

const DamageIndicator = ({ x, y, damage }) => (
  <div className="damage-indicator" style={{ left: x, top: y }}>
    +{damage}
  </div>
);

function EatsApp({ setIsTabOpen }) {
  const [showTutorial, setShowTutorial] = useState(false);
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Tab / UI states
  const [activeTab, setActiveTab] = useState(null);
  const [isTabOpenState, setIsTabOpenState] = useState(false);
  const [showButtons, setShowButtons] = useState(true);

  // Tap logic
  const [isImageDistorted, setIsImageDistorted] = useState(false);
  const [tapMessage, setTapMessage] = useState('');
  const [damageIndicators, setDamageIndicators] = useState([]);

  // Fast-tap tracking
  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [lastMessageTime, setLastMessageTime] = useState(0);

  // Tap multiplier countdown
  const [multiplierCountdown, setMultiplierCountdown] = useState(0);

  // Refs
  const clickerRef = useRef(null);

  // We'll store session_id here for general usage
  const sessionIdRef = useRef(null);

  // Limit number of ripple elements to avoid glitching
  const MAX_RIPPLES = 10;

  // Detect if it's a touch device
  const isTouchDevice =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  // 1) Show tutorial if first time
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    if (!hasVisitedBefore) {
      setShowTutorial(true);
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);

  const handleTutorialClose = () => setShowTutorial(false);

  // 2) Delay showing bottom nav
  useEffect(() => {
    setTimeout(() => setShowButtons(true), 100);
  }, []);

  // 3) Telegram WebApp init
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();

      document.documentElement.style.height = '100%';
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    }
  }, []);

  // 4) Expand again after loading => fix partial rendering
  useEffect(() => {
    if (!isLoading && window.Telegram?.WebApp) {
      setTimeout(() => {
        window.Telegram.WebApp.expand();
      }, 200);
    }
  }, [isLoading]);

  // 5) Prevent pinch-zoom by blocking multi-finger touchmove
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  // 6) On mount => parse session_id from URL, fetch game_state
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    if (!sessionId) {
      console.warn('[EatsApp] No session_id in URL => cannot load data');
      setIsLoading(false);
      return;
    }

    sessionIdRef.current = sessionId;
    console.log('[EatsApp] Found session_id:', sessionId);

    setIsLoading(true);

    // Fetch /game_state using session_id
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    fetch(`${backendUrl}/game_state?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) throw new Error(`Error fetching game_state: ${res.status}`);
        return res.json();
      })
      .then((data) => {
        // Initialize UniverseData with the server's response
        const localData = UniverseData.initialize(data);

        // IMPORTANT: Attach session_id so NotificationIcon sees it
        localData.session_id = sessionId;

        // Now set to state => your gameState includes .session_id
        setGameState({ ...localData });
      })
      .catch((err) => {
        console.error('[EatsApp] /game_state fetch error:', err);
        setError(err.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // 7) Periodic sync with server
  useEffect(() => {
    const timer = setInterval(() => {
      if (!sessionIdRef.current) return;
      // pass the session_id to UniverseData's sync
      UniverseData.syncActions(sessionIdRef.current, false);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  // 8) Final sync on close
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!sessionIdRef.current) return;
      UniverseData.syncActions(sessionIdRef.current, true);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // 9) Local real-time regeneration
  useEffect(() => {
    const regenTimer = setInterval(() => {
      setGameState((prev) => {
        if (!prev?.ewi) return prev;
        const clone = JSON.parse(JSON.stringify(prev));
        const { energy, maxEnergy, regenRate } = clone.ewi;
        if (energy < maxEnergy) {
          clone.ewi.energy = Math.min(energy + regenRate, maxEnergy);
        }
        // Keep UniverseData in sync
        UniverseData.getCurrentData().ewi.energy = clone.ewi.energy;
        return clone;
      });
    }, 1000);
    return () => clearInterval(regenTimer);
  }, []);

  // 10) Check if tapMultiplier expired
  useEffect(() => {
    const interval = setInterval(() => {
      UniverseData.checkAndRevertTapMultiplier();
      setGameState((prev) => {
        if (!prev?.ewi) return prev;
        const clone = JSON.parse(JSON.stringify(prev));

        // Re-check from UniverseData
        const updated = UniverseData.getCurrentData()?.ewi || {};
        clone.ewi.tapMultiplier = updated.tapMultiplier;
        clone.ewi.tapMultiplierEnd = updated.tapMultiplierEnd;

        if (clone.ewi.tapMultiplier > 1 && clone.ewi.tapMultiplierEnd) {
          const remaining = clone.ewi.tapMultiplierEnd - Date.now();
          setMultiplierCountdown(remaining > 0 ? Math.ceil(remaining / 1000) : 0);
        } else {
          setMultiplierCountdown(0);
        }
        return clone;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Toggle bottom bar depending on tab
  useEffect(() => {
    setShowButtons(!isTabOpenState);
  }, [isTabOpenState]);

  // ======================== TASK & DAILY CLAIM =========================
  const handleTaskClaimFromParent = (taskIndex, reward, rewardType) => {
    UniverseData.handleTaskClaim(taskIndex, reward, rewardType);
    setGameState({ ...UniverseData.getCurrentData() });
  };

  const handleDailyClaimFromParent = (dayNumber, rewardString) => {
    UniverseData.handleDailyClaim(dayNumber, rewardString);
    setGameState({ ...UniverseData.getCurrentData() });
  };

  // ========================= MULTI-TAP LOGIC ===========================
  const processTap = (x, y) => {
    const data = UniverseData.getCurrentData();
    if (!data?.ewi) return;
  
    const { damageLevel = 1, tapMultiplier = 1 } = data.ewi;
    const totalDamage = damageLevel * tapMultiplier;
  
    const success = UniverseData.handleClick(totalDamage);
    if (!success) {
      setTapMessage('Not enough energy!');
      return;
    }
    setGameState({ ...UniverseData.getCurrentData() });
  
    const now = Date.now();
    const timeDiff = now - lastClickTime;
    setLastClickTime(now);
  
    if (timeDiff < 500) {
      setClickCount((prev) => prev + 1);
    } else {
      setClickCount(1);
    }
    if (clickCount >= 8 && timeDiff <= 1000) {
      if (now - lastMessageTime >= 3000) {
        const msg = tapMessages[Math.floor(Math.random() * tapMessages.length)];
        setTapMessage(msg);
        setLastMessageTime(now);
      }
    } else {
      setTapMessage('');
    }
  
    // Рассчитываем угол наклона
    const rect = clickerRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
  
    const deltaX = x - centerX;
    const deltaY = y - centerY;
  
    const angleX = (deltaY / rect.height) * -35; // Наклон по X (максимум 15°)
    const angleY = (deltaX / rect.width) * 35; // Наклон по Y (максимум -15°)
  
    // Устанавливаем трансформацию
    clickerRef.current.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg)`;
  
    // Возвращаем в исходное состояние плавно
    setTimeout(() => {
      clickerRef.current.style.transform = `rotateX(0deg) rotateY(0deg)`;
    }, 200);
  
    // R I P P L E
    const ripple = document.createElement('div');
    ripple.className = 'ripple';
    ripple.style.left = `${x - rect.left}px`;
    ripple.style.top = `${y - rect.top}px`;
    clickerRef.current.appendChild(ripple);
  
    const existing = clickerRef.current.querySelectorAll('.ripple');
    if (existing.length > MAX_RIPPLES) {
      existing[0].remove();
    }
  
    setTimeout(() => ripple.remove(), 800);
  
    // Damage Indicator
    const newIndicator = {
      id: Date.now() + Math.random(),
      x: x - rect.left,
      y: y - rect.top,
      damage: totalDamage
    };
    setDamageIndicators((prev) => {
      const next = [...prev, newIndicator];
      if (next.length > 25) next.shift();
      return next;
    });
    setTimeout(() => {
      setDamageIndicators((prev) => prev.filter((i) => i.id !== newIndicator.id));
    }, 1000);
  };
  
  const handleClickDesktop = (e) => {
    e.preventDefault();
    processTap(e.clientX, e.clientY);
  };

  const handleTouchMobile = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.touches.length; i++) {
      const touch = e.touches[i];
      processTap(touch.clientX, touch.clientY);
    }
  };

  // ===================== UPGRADE & BOOST ========================
  const handleUpgrade = (upgradeType, cost) => {
    const success = UniverseData.handleUpgrade(upgradeType, cost);
    if (!success) {
      setTapMessage('Not enough EWI!');
      return;
    }
    setGameState({ ...UniverseData.getCurrentData() });
  };

  const handleBoost = (boostType) => {
    UniverseData.handleBoost(boostType, false);
    setGameState({ ...UniverseData.getCurrentData() });
  };

  useEffect(() => {
    if (tapMessage) {
      const t = setTimeout(() => setTapMessage(''), 3000);
      return () => clearTimeout(t);
    }
  }, [tapMessage]);

  // ===================== TABS LOGIC ======================
  const handleTabOpen = (tab) => {
    setActiveTab(tab);
    setIsTabOpenState(true);
    setIsTabOpen?.(true);
    setShowButtons(false);
  };

  const handleBackButtonClick = () => {
    setActiveTab(null);
    setIsTabOpenState(false);
    setIsTabOpen?.(false);
    setShowButtons(true);
  };

  // Manually refresh local data after claims
  const onNotificationsClaimed = async (referralUserId, bonusEwi, bonusEwe) => {
    console.log(
      '[EatsApp] onNotificationsClaimed => referralUserId=',
      referralUserId,
      'EWI=',
      bonusEwi,
      'EWE=',
      bonusEwe
    );
    if (!sessionIdRef.current) return;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/game_state?session_id=${sessionIdRef.current}`
      );
      if (res.ok) {
        const data = await res.json();
        UniverseData.initialize(data);
        // Re-attach session_id
        data.session_id = sessionIdRef.current;
        setGameState({ ...data });
      }
    } catch (err) {
      console.error('[onNotificationsClaimed] refresh error:', err);
    }
  };

  // ===================== GUARD STATES ======================
  if (!sessionIdRef.current && !gameState && !isLoading && !error) {
    return (
      <div className="no-user">
        <h3>No valid session ID found. Please open via Telegram Bot link.</h3>
      </div>
    );
  }
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!gameState) return <div>No game data available</div>;

  // Shortcut variables
  const ewi = gameState.ewi || {};
  const energy = ewi.energy || 0;
  const maxEnergy = ewi.maxEnergy || 1000;
  const remainingEnergyPercentage = ((maxEnergy - energy) / maxEnergy) * 100;

  return (
    <div className="App">
      <Background />

      {showTutorial && <Tutorial onClose={handleTutorialClose} />}

      {/* TABS */}
      {isTabOpenState && activeTab === 'TASKS' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <TasksTab
            tasksState={gameState.tasks}
            handleTaskClaimFromParent={handleTaskClaimFromParent}
          />
        </div>
      )}
      {isTabOpenState && activeTab === 'REFERRAL' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <ReferralShare />
        </div>
      )}
      {isTabOpenState && activeTab === 'GIFTBOX' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <GiftTab />
        </div>
      )}
      {isTabOpenState && activeTab === 'DAILY_CLAIM' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <DailyBonus onDailyClaimFromParent={handleDailyClaimFromParent} />
        </div>
      )}
      {isTabOpenState && activeTab === 'LEADERBOARD' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <Leaderboard players={[]} />
        </div>
      )}
      {isTabOpenState && activeTab === 'BOOST' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <BoostTab />
        </div>
      )}
      {isTabOpenState && activeTab === 'UPGRADE' && (
        <div className="tab-container">
          <button className="back-button" onClick={handleBackButtonClick}>
            BACK
          </button>
          <UpgradeTab
            damageLevel={ewi.damageLevel || 1}
            energyLevel={ewi.energyLevel || 1}
            regenLevel={ewi.regenLevel || 1}
            currentEWI={ewi.currentEWI || 0}
            handleUpgrade={handleUpgrade}
            handleBoost={handleBoost}
			onCloseUpgrades={handleBackButtonClick}
            dailyBoostMe={ewi.dailyboostme || 0}
            dailyBoostMt={ewi.dailyboostmt || 0}
          />
        </div>
      )}

      {/* MAIN SCREEN */}
      {!isTabOpenState && (
        <header className="App-header">
          {/* IMPORTANT: userData now includes session_id so NotificationIcon can fetch */}
          <NotificationIcon
            userData={gameState}
            onNotificationsClaimed={onNotificationsClaimed}
          />

          <div className="up-cont">
            <div className="bs1">
              <UserLogo defaultLogo={logo} />
            </div>

            <div className="energy-container">
              <p className="Energy-descr">{Math.floor(energy)}</p>
              <img src={Energ} alt="Energy Icon" className="energy-icon" />
            </div>

            <div className="total-e">
              <ProgressBar remainingEnergyPercentage={remainingEnergyPercentage} />
            </div>
          </div>

          <div className="tasks-icon-container">
            <button onClick={() => handleTabOpen('TASKS')}>
              <img className="icon" src={Tasks} alt="Tasks" />
            </button>
            <button className="disabled">
              <img className="icon" src={GiftBoxIcon} alt="Gift Box" />
            </button>
            <button onClick={() => handleTabOpen('DAILY_CLAIM')}>
              <img className="icon" src={DailyClaimIcon} alt="Daily Claim" />
            </button>
          </div>

          <div className="balance-container">
            <img src={clickerImage} alt="Balance Icon" className="balance-icon" />
            <p>{Math.floor(ewi.currentEWI || 0)}</p>
          </div>

          {ewi.tapMultiplier > 1 && multiplierCountdown > 0 && (
            <div className="tap-message">
              Tap x3 active: {multiplierCountdown}s left
            </div>
          )}
          {(ewi.tapMultiplier === 1 || !ewi.tapMultiplier) && tapMessage && (
            <div className="tap-message">{tapMessage}</div>
          )}

          <div
            className="clicker-container"
            ref={clickerRef}
            {...(isTouchDevice
              ? { onTouchStart: handleTouchMobile }
              : { onClick: handleClickDesktop })}
          >
            <img
              src={clickerImage}
              alt="Clicker"
              className={`clicker-image ${isImageDistorted ? 'distorted' : ''}`}
            />
            {damageIndicators.map((indicator) => (
              <DamageIndicator
                key={indicator.id}
                x={indicator.x}
                y={indicator.y}
                damage={indicator.damage}
              />
            ))}
          </div>
        </header>
      )}

      {!isTabOpenState && showButtons && (
        <div className="tabs">
          <button onClick={() => handleTabOpen('REFERRAL')}>
            <img className="icon" src={Ref} alt="Ref" />
          </button>
          <button className="disabled">
            <img className="icon" src={Wallet} alt="Wallet" />
          </button>
          <button onClick={() => handleTabOpen('BOOST')}>
            <img className="icon" src={Games} alt="Games" />
          </button>
          <button className="disabled">
            <img className="icon" src={Birge} alt="Exchange" />
          </button>
          <button onClick={() => handleTabOpen('UPGRADE')}>
            <img className="icon" src={Upgrade} alt="Upgrade" />
          </button>
        </div>
      )}
    </div>
  );
}

export default EatsApp;
