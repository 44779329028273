// UniverseData.js

class UniverseData {
  constructor() {
    this.localData = null;
    this.actionQueue = [];
    this.isSyncing = false;
    this.currentGameSession = null;

    // We'll store the sessionId separately
    this.sessionId = null;
  }

  /**
   * Call this after parsing `session_id` from the URL in your React code:
   *    UniverseData.setSessionId(sessionIdFromURL);
   */
  setSessionId(sessionId) {
    this.sessionId = sessionId;
    console.log('[UniverseData] sessionId set to:', sessionId);
  }

  /**
   * Initialize local data from the server's /game_state response.
   * The server_data does NOT need to have session_id; we keep using
   * localData for EWI/EWE/gameState, but actual user_id is no longer
   * used in sync calls.
   */
  initialize(serverData) {
    console.log('[UniverseData] Initializing with server data:', serverData);

    const realUserId = serverData.user_id ?? null;

    const dailyRewardsFallback = {
      last_claim: null,
      streak_days: 0
    };
    const serverDaily = serverData.daily_rewards || dailyRewardsFallback;
    let lastClaimLocal = serverDaily.last_claim ? new Date(serverDaily.last_claim) : null;

    // EWI fallback
    const ewiFallback = {
      energy: 1000,
      maxEnergy: 1000,
      energyLevel: 1,
      regenRate: 1,
      damageLevel: 1,
      regenLevel: 1,
      totalEWI: 0,
      currentEWI: 0,
      tapMultiplier: 1,
      tapMultiplierEnd: 0
    };
    const ewiData = serverData.ewi || {};
    const mergedEwi = { ...ewiFallback, ...ewiData };

    if (typeof mergedEwi.tapMultiplier !== 'number') {
      mergedEwi.tapMultiplier = 1;
    }
    if (typeof mergedEwi.tapMultiplierEnd !== 'number') {
      mergedEwi.tapMultiplierEnd = 0;
    }

    // EWE fallback
    const eweFallback = {
      tokens: 0.0,
      farmedTokens: 0.0,
      isFarming: false,
      elapsedFarmingTime: 0.0
    };
    const eweData = serverData.ewe || {};
    const mergedEwe = { ...eweFallback, ...eweData };

    // Bombs fallback
    const bombsFallback = {
      attempts: 3
    };
    const bombsData = serverData.bombs || {};
    const mergedBombs = { ...bombsFallback, ...bombsData };

    this.localData = {
      user_id: realUserId,
      is_new_user: serverData.is_new_user,
      telegram_name: serverData.telegram_name,
      guide: serverData.guide,
      stars: serverData.stars,
      ewi: mergedEwi,
      ewe: mergedEwe,
      bombs: mergedBombs,
      tasks: serverData.tasks || {},
      referral_code: serverData.referral_code,

      dailyRewards: {
        last_claim: lastClaimLocal,
        streak_days: serverDaily.streak_days || 0
      }
    };

    this.actionQueue = [];
    this.isSyncing = false;
    this.currentGameSession = null;

    return this.localData;
  }

  getCurrentData() {
    return this.localData;
  }

  // ======================= EWE UPDATE =======================
  updateEweData(eweFields) {
    if (!this.localData) {
      console.warn('[UniverseData] No localData => cannot update EWE');
      return false;
    }
    if (!this.localData.ewe) {
      this.localData.ewe = {};
    }

    // Merge new fields into local EWE data
    Object.assign(this.localData.ewe, eweFields);

    // Queue a minimal action so the server can re-verify
    this.actionQueue.push({
      type: 'ewe_update',
      data: { ...eweFields }
    });

    console.log('[UniverseData] EWE updated =>', this.localData.ewe);
    return true;
  }

  // ======================= TASKS =======================
  handleTaskClaim(taskIndex, reward, rewardType) {
    if (!this.localData) {
      console.warn('[UniverseData] No localData => cannot claim task');
      return false;
    }
    if (!this.localData.tasks) {
      this.localData.tasks = {};
    }

    const key = `task_${taskIndex}`;
    if (this.localData.tasks[key] === true) {
      console.warn(`[UniverseData] Task ${taskIndex} already claimed locally.`);
      return false;
    }
    this.localData.tasks[key] = true;

    // Local increments (so user sees immediate changes)
    if (rewardType === 1) {
      // EWI
      this.localData.ewi.currentEWI += parseInt(reward, 10);
      this.localData.ewi.totalEWI += parseInt(reward, 10);
    } else if (rewardType === 2) {
      // EWE
      this.localData.ewe.tokens += parseFloat(reward);
    }

    // Queue minimal data
    this.actionQueue.push({
      type: 'task_claim',
      data: { taskIndex }
    });
    console.log(`[UniverseData] Task ${taskIndex} claimed (local increments).`);
    return true;
  }

  handleTaskVisit(taskIndex) {
    this.actionQueue.push({
      type: 'task_visit',
      data: { taskIndex }
    });
    console.log(`[UniverseData] Task ${taskIndex} visited => queued 'task_visit'`);
    return true;
  }

  // ======================= DAILY CLAIM =======================
  handleDailyClaim(dayNumber, rewardString) {
    if (!this.localData) {
      console.warn('[UniverseData] No localData => cannot claim daily reward');
      return false;
    }
    if (!this.localData.dailyRewards) {
      this.localData.dailyRewards = { last_claim: null, streak_days: 0 };
    }

    const now = new Date();
    const lastClaim = this.localData.dailyRewards.last_claim
      ? new Date(this.localData.dailyRewards.last_claim)
      : null;

    // Check if user already claimed “today” (UTC day)
    if (lastClaim) {
      const nowUTC = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      );
      const lastClaimUTC = new Date(
        Date.UTC(
          lastClaim.getUTCFullYear(),
          lastClaim.getUTCMonth(),
          lastClaim.getUTCDate()
        )
      );
      if (nowUTC.getTime() === lastClaimUTC.getTime()) {
        console.warn('[UniverseData] Already claimed daily reward today');
        return false;
      }
    }

    // Increase streak or reset to 1
    let newStreak = 1;
    if (lastClaim) {
      const diffTime = now.getTime() - lastClaim.getTime();
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 1) {
        newStreak = this.localData.dailyRewards.streak_days + 1;
      } else {
        newStreak = 1;
      }
    }
    if (newStreak > 7) {
      newStreak = 1;
    }

    // Local increments (5000 EWI or 1 EWE on day 7)
    if (newStreak === 7) {
      if (this.localData.ewe) {
        this.localData.ewe.tokens += 1.0;
      }
    } else {
      if (this.localData.ewi) {
        this.localData.ewi.currentEWI += 5000;
        this.localData.ewi.totalEWI += 5000;
      }
    }

    // Update local daily info
    this.localData.dailyRewards.last_claim = now;
    this.localData.dailyRewards.streak_days = newStreak;

    // Queue minimal data
    this.actionQueue.push({
      type: 'daily_claim',
      data: { dayNumber: newStreak }
    });

    console.log(`[UniverseData] handleDailyClaim => day=${newStreak}, local increments done.`);
    return true;
  }

  getDailyRewardData() {
    return this.localData?.dailyRewards || {
      last_claim: null,
      streak_days: 0
    };
  }

  // ======================= ENERGY / TAPS =======================
  handleClick(damage) {
    if (!this.localData?.ewi) {
      return false;
    }
    if (this.localData.ewi.energy < damage) {
      console.warn('[UniverseData] Not enough energy for click');
      return false;
    }
    this.localData.ewi.energy -= damage;
    this.localData.ewi.currentEWI += damage;
    this.localData.ewi.totalEWI += damage;

    this.actionQueue.push({
      type: 'click',
      data: { damage }
    });
    return true;
  }

  // ======================= BOOSTS =======================
  handleBoost(boostType, isAd = false) {
    if (!this.localData?.ewi) {
      console.warn('[UniverseData] No ewi => cannot boost');
      return false;
    }
    if (boostType === 'maxEnergy') {
      this.localData.ewi.energy = this.localData.ewi.maxEnergy;
    } else if (boostType === 'tapMultiplier') {
      this.localData.ewi.tapMultiplier = 3;
      this.localData.ewi.tapMultiplierEnd = Date.now() + 60 * 1000;
    } else {
      console.warn('[UniverseData] handleBoost => unknown boostType:', boostType);
      return false;
    }

    this.actionQueue.push({
      type: 'boost',
      data: {
        boost_type: boostType,
        is_ad: isAd,
        duration_seconds: 60
      }
    });
    return true;
  }

  checkAndRevertTapMultiplier() {
    if (!this.localData?.ewi) return;
    if (this.localData.ewi.tapMultiplier > 1) {
      const now = Date.now();
      if (now >= this.localData.ewi.tapMultiplierEnd) {
        this.localData.ewi.tapMultiplier = 1;
        this.localData.ewi.tapMultiplierEnd = 0;
        console.log('[UniverseData] Tap multiplier ended');
      }
    }
  }

  // ======================= UPGRADES =======================
  handleUpgrade(upgradeType, cost) {
    if (!this.localData?.ewi) {
      console.error('[UniverseData] Missing ewi');
      return false;
    }
    const numericCost = Number(cost) || 0;
    if (this.localData.ewi.currentEWI < numericCost) {
      console.warn('[UniverseData] Not enough EWI to upgrade');
      return false;
    }

    this.localData.ewi.currentEWI -= numericCost;

    switch (upgradeType) {
      case 'damage':
        this.localData.ewi.damageLevel++;
        break;
      case 'regen':
        this.localData.ewi.regenLevel++;
        this.localData.ewi.regenRate = Math.floor(
          this.localData.ewi.regenLevel * 1.5
        );
        break;
      case 'energy':
        this.localData.ewi.energyLevel++;
        this.localData.ewi.maxEnergy =
          1000 + (this.localData.ewi.energyLevel - 1) * 500;
        break;
      default:
        console.warn('[UniverseData] Unknown upgradeType', upgradeType);
        return false;
    }

    this.actionQueue.push({
      type: 'upgrade',
      data: { upgrade_type: upgradeType, cost: numericCost }
    });
    return true;
  }

  // ======================= BOMBS / GAMES =======================
  handleBomb(reason = 'start_game') {
    if (!this.localData?.bombs) {
      console.warn('[UniverseData] No bombs data => cannot handleBomb');
      return false;
    }

    if (reason === 'start_game') {
      if (this.localData.bombs.attempts <= 0) {
        console.warn('[UniverseData] No bomb attempts left => cannot start');
        return false;
      }
      this.localData.bombs.attempts--;

      this.currentGameSession = {
        sessionId: `game_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        startTime: Date.now(),
        collectedItems: [],
        finalScore: 0,
        completed: false
      };

      this.actionQueue.push({
        type: 'bomb',
        data: {
          reason,
          session_id: this.currentGameSession.sessionId,
          start_time: this.currentGameSession.startTime
        }
      });

      return true;
    }

    if (reason === 'end_game') {
      if (!this.currentGameSession) {
        console.warn('[UniverseData] No active game session => cannot end');
        return false;
      }

      this.currentGameSession.completed = true;
      this.currentGameSession.endTime = Date.now();

      this.actionQueue.push({
        type: 'game_end',
        data: {
          session_id: this.currentGameSession.sessionId,
          end_time: this.currentGameSession.endTime,
          duration: this.currentGameSession.endTime - this.currentGameSession.startTime,
          collected_items: this.currentGameSession.collectedItems,
          final_score: this.currentGameSession.finalScore
        }
      });

      return true;
    }

    console.warn('[UniverseData] handleBomb => unknown reason:', reason);
    return false;
  }

  updateGameSession(collectedItem) {
    if (!this.currentGameSession) {
      console.warn('[UniverseData] No active game session => cannot updateGameSession');
      return false;
    }
    this.currentGameSession.collectedItems.push({
      ...collectedItem,
      timestamp: Date.now()
    });
    return true;
  }

  setGameSessionScore(score) {
    if (!this.currentGameSession) {
      console.warn('[UniverseData] No active game session => cannot setGameSessionScore');
      return false;
    }
    this.currentGameSession.finalScore = score;
    return true;
  }

  // ======================= REFERRAL BONUS =======================
  async claimReferralBonus(userId, referralUserId) {
    try {
      const body = { user_id: userId, referral_user_id: referralUserId };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/claim_bonus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      if (!res.ok) {
        console.error('[UniverseData] claimReferralBonus error =>', res.status, data);
        return null;
      }
      console.log('[UniverseData] Successfully claimed referral bonus =>', data);
      return data;
    } catch (err) {
      console.error('[UniverseData] claimReferralBonus exception =>', err);
      return null;
    }
  }

  // ======================= SYNC ACTIONS (uses session_id) =======================
  async syncActions(sessionId, isFinal = false) {
    // 1) If a sessionId is passed, store it
    if (sessionId) {
      this.sessionId = sessionId;
    }

    // 2) If no localData or if we're already syncing, skip
    if (!this.localData || this.isSyncing) {
      return;
    }

    // 3) If actionQueue is empty, skip (no empty posts)
    if (!this.actionQueue.length) {
      console.log('[UniverseData] skip sync, queue is empty');
      return;
    }

    // 4) If no sessionId, skip
    if (!this.sessionId) {
      console.warn('[UniverseData] No sessionId set => cannot sync');
      return;
    }

    this.isSyncing = true;
    console.log('[UniverseData] syncActions => sending', this.actionQueue, ' isFinal=', isFinal);

    const payload = {
      session_id: this.sessionId,
      actions: this.actionQueue,
      is_final: isFinal
    };

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sync_actions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
        keepalive: isFinal
      });
      const data = await res.json();
      console.log('[UniverseData] Server responded =>', res.status, data);

      if (!res.ok) {
        console.error('[UniverseData] syncActions error =>', res.status, data);
      } else if (data.gameState) {
        // If server sends updated game state, overwrite local
        this.localData = data.gameState;
        console.log('[UniverseData] Updated game state from server');
      }

      // Clear the local queue
      this.actionQueue = [];

      if (isFinal) {
        this.currentGameSession = null;
      }
    } catch (err) {
      console.error('[UniverseData] syncActions exception =>', err);
    } finally {
      this.isSyncing = false;
    }
  }
}

export default new UniverseData();
