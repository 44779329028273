import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import EWE from '../Universes/EWE/src/EWE';
import EcoGame from '../Universes/ECI/EcoGame';
import EatsApp from '../Universes/EWI/src/EatsApp';
import '../css/UniverseSwitcher.css';
import loadingImage from '../components/public/load_screen_univ.avif';

const UniverseSwitcher = () => {
  const [currentUniverse, setCurrentUniverse] = useState('EWI');
  const [nextUniverse, setNextUniverse] = useState('');
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // The sequence of universes
  const universeOrder = ['EWE', 'ECI', 'EWI'];

  // Thresholds for react-swipeable
  const SWIPE_THRESHOLD = 200;  // Minimum horizontal distance
  const SWIPE_VELOCITY = 0.3;   // Minimum velocity

  const getNextUniverse = (direction) => {
    const currentIndex = universeOrder.indexOf(currentUniverse);
    if (direction === 'next') {
      return universeOrder[(currentIndex + 1) % universeOrder.length];
    } else if (direction === 'prev') {
      return universeOrder[(currentIndex - 1 + universeOrder.length) % universeOrder.length];
    }
  };

  const changeUniverse = (direction) => {
    setIsLoading(true);
    const next = getNextUniverse(direction);
    setNextUniverse(next);

    // Simulate loading time
    setTimeout(() => {
      setCurrentUniverse(next);
      setIsLoading(false);
    }, 3000);
  };

  // Attempt to block vertical swipes that might trigger the Telegram “pull-down.”
  // Still allow left/right swipes for dimension switching.
  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      // If the user is swiping up or down, prevent it
      if (eventData.dir === 'Up' || eventData.dir === 'Down') {
        eventData.event.preventDefault();
      }
    },
    onSwipedLeft: ({ deltaX, velocity }) => {
      if (
        Math.abs(deltaX) >= SWIPE_THRESHOLD &&
        velocity >= SWIPE_VELOCITY &&
        !isTabOpen &&
        !isLoading
      ) {
        changeUniverse('next');
      }
    },
    onSwipedRight: ({ deltaX, velocity }) => {
      if (
        Math.abs(deltaX) >= SWIPE_THRESHOLD &&
        velocity >= SWIPE_VELOCITY &&
        !isTabOpen &&
        !isLoading
      ) {
        changeUniverse('prev');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true
  });

  const renderUniverse = () => {
    switch (currentUniverse) {
      case 'EWE':
        return <EWE setIsTabOpen={setIsTabOpen} />;
      case 'ECI':
        return <EcoGame setIsTabOpen={setIsTabOpen} />;
      case 'EWI':
        return <EatsApp setIsTabOpen={setIsTabOpen} />;
      default:
        return <EWE setIsTabOpen={setIsTabOpen} />;
    }
  };

  return (
    <div className="container" {...handlers}>
      <main>
        {isLoading ? (
          <div className="loading-screen">
            <img src={loadingImage} alt="Loading" />
            <p>Переход во вселенную {nextUniverse}</p>
          </div>
        ) : (
          renderUniverse()
        )}
      </main>
    </div>
  );
};

export default UniverseSwitcher;
