import React, { useState, useMemo, useEffect } from 'react';
import UniverseData from '../UniverseData';
import '../css.components/UpgradeTab.css';

import BOT from '../../public/bot.gif';
import BOOST1 from '../../public/refresh.gif';
import BOOST2 from '../../public/boosttap.gif';
import DAMAGE_UPGRADE from '../../public/click.gif';
import ENERGY_UPGRADE from '../../public/Energy.gif';
import REGEN_UPGRADE from '../../public/regen.gif';

/* Short explanation:
   This component shows upgrades and daily boosts. We keep local "daily usage" states
   so the UI immediately updates if a boost is used (without needing a full refresh).
*/

function getDamageCost(damageLevel) {
  return 200 * Math.pow(damageLevel + 1, 4);
}
function getEnergyCost(energyLevel) {
  return 150 * Math.pow(energyLevel + 1, 4);
}
function getRegenCost(regenLevel) {
  return 250 * Math.pow(regenLevel + 1, 4);
}

function getMsUntilNextMidnightUTC() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const nextMidnight = new Date(Date.UTC(year, month, day + 1, 0, 0, 0));
  return nextMidnight.getTime() - now.getTime();
}

function formatHMS(ms) {
  if (ms <= 0) return '00:00:00';
  const totalSeconds = Math.floor(ms / 1000);
  const hrs = Math.floor(totalSeconds / 3600);
  const mins = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;

  const hh = String(hrs).padStart(2, '0');
  const mm = String(mins).padStart(2, '0');
  const ss = String(secs).padStart(2, '0');

  return `${hh}:${mm}:${ss}`;
}

const UpgradeTab = ({
  damageLevel,
  energyLevel,
  regenLevel,
  currentEWI,
  handleUpgrade,
  handleBoost,
  dailyBoostMe,
  dailyBoostMt,
  onCloseUpgrades
}) => {
  const [buttonState, setButtonState] = useState({
    damage: '',
    energy: '',
    regen: '',
  });
  const [boostButtonState, setBoostButtonState] = useState({
    maxEnergy: '',
    tapMultiplier: '',
  });

  // Local daily usage states (start from props, don't resync to them)
  const [localDailyBoostMe, setLocalDailyBoostMe] = useState(dailyBoostMe);
  const [localDailyBoostMt, setLocalDailyBoostMt] = useState(dailyBoostMt);

  const [timeToMidnight, setTimeToMidnight] = useState(getMsUntilNextMidnightUTC());
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToMidnight(getMsUntilNextMidnightUTC());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const damageCost = useMemo(() => getDamageCost(damageLevel), [damageLevel]);
  const energyCost = useMemo(() => getEnergyCost(energyLevel), [energyLevel]);
  const regenCost = useMemo(() => getRegenCost(regenLevel), [regenLevel]);
  const countdownLabel = formatHMS(timeToMidnight);

  const hasUsedMaxEnergyToday = localDailyBoostMe >= 1;
  const hasUsedTapMultiplierToday = localDailyBoostMt >= 1;

  const handleUpgradeClick = (upgradeType, costKey) => {
    setButtonState((prev) => ({ ...prev, [upgradeType]: 'onclic' }));
    handleUpgrade(upgradeType, costKey);
    UniverseData.syncActions(null, false);

    setTimeout(() => {
      setButtonState((prev) => ({ ...prev, [upgradeType]: 'validate' }));
      setTimeout(() => {
        setButtonState((prev) => ({ ...prev, [upgradeType]: '' }));
      }, 1250);
    }, 2250);
  };

  const handleBoostClick = (boostType) => {
    setBoostButtonState((prev) => ({ ...prev, [boostType]: 'onclic' }));
    handleBoost(boostType);
    UniverseData.syncActions(null, false);

    if (boostType === 'maxEnergy') {
      setLocalDailyBoostMe(1);
    } else if (boostType === 'tapMultiplier') {
      setLocalDailyBoostMt(1);
    }

    if (onCloseUpgrades) {
      onCloseUpgrades();
    }

    setTimeout(() => {
      setBoostButtonState((prev) => ({ ...prev, [boostType]: 'validate' }));
      setTimeout(() => {
        setBoostButtonState((prev) => ({ ...prev, [boostType]: '' }));
      }, 1250);
    }, 2250);
  };

  return (
    <div className="upgrades">
      <div className="upgrade-section">
        <h4 className="header_upg">Upgrades</h4>
        <button
          className={buttonState.damage}
          onClick={() => handleUpgradeClick('damage', damageCost)}
          disabled={currentEWI < damageCost}
        >
          <div className="upgrade-icon">
            <img src={DAMAGE_UPGRADE} alt="Damage Upgrade Animation" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Damage {damageLevel}</h4>
          <p>+1 damage per click</p>
          <p>Cost: {damageCost} EWI</p>
        </button>

        <button
          className={buttonState.energy}
          onClick={() => handleUpgradeClick('energy', energyCost)}
          disabled={currentEWI < energyCost}
        >
          <div className="upgrade-icon">
            <img src={ENERGY_UPGRADE} alt="Energy Upgrade Animation" width="50" height="50" />
          </div>
          <h4 className="upgrade-txt">Energy {energyLevel}</h4>
          <p>+500 max energy</p>
          <p>Cost: {energyCost} EWI</p>
        </button>

        {regenLevel < 5 ? (
          <button
            className={buttonState.regen}
            onClick={() => handleUpgradeClick('regen', regenCost)}
            disabled={currentEWI < regenCost}
          >
            <div className="upgrade-icon">
              <img src={REGEN_UPGRADE} alt="Regeneration Upgrade Animation" width="50" height="50" />
            </div>
            <h4 className="upgrade-txt">Regen {regenLevel}</h4>
            <p>+1 regeneration speed</p>
            <p>Cost: {regenCost} EWI</p>
          </button>
        ) : (
          <div className="upgrade-placeholder">
            <h4 className="upgrade-txt">Max Regeneration Level Reached</h4>
          </div>
        )}
      </div>

      <div className="autofarm-section">
        <h4 className="header_upg">Auto-Farming Bot</h4>
        <button className="disabled-btn" disabled>
          <div className="bot-icon">
            <img src={BOT} alt="Bot Animation" width="50" height="50" />
          </div>
          <p className="soon-text">SOON</p>
        </button>
      </div>

      <div className="boost-section">
        <h4 className="header_upg">Boosts</h4>
        <div className="boost-row">
          <button
            className={boostButtonState.maxEnergy}
            onClick={() => handleBoostClick('maxEnergy')}
            disabled={hasUsedMaxEnergyToday}
          >
            <div className="boost-icon">
              <img src={BOOST1} alt="Boost 1 Animation" width="50" height="50" />
            </div>
            <h4>MAX Energy</h4>
            <p>Fully restores your energy.</p>
            {hasUsedMaxEnergyToday ? (
              <span className="tooltip">Used! Resets in {countdownLabel}</span>
            ) : (
              <span className="tooltip">1 free daily usage</span>
            )}
          </button>

          <button className="disabled-btn" disabled>
            <div className="boost-icon">
              <img src={BOOST1} alt="Boost 1 Animation" width="25" height="25" />
            </div>
            <p>Watch Ad (coming soon)</p>
          </button>
        </div>

        <div className="boost-row">
          <button
            className={boostButtonState.tapMultiplier}
            onClick={() => handleBoostClick('tapMultiplier')}
            disabled={hasUsedTapMultiplierToday}
          >
            <div className="boost-icon">
              <img src={BOOST2} alt="Boost 2 Animation" width="50" height="50" />
            </div>
            <h4>Progressive TAP</h4>
            <p>Multiplies your taps by 3 for 1 minute</p>
            {hasUsedTapMultiplierToday ? (
              <span className="tooltip">Used! Resets in {countdownLabel}</span>
            ) : (
              <span className="tooltip">1 free daily usage</span>
            )}
          </button>

          <button className="disabled-btn" disabled>
            <div className="boost-icon">
              <img src={BOOST2} alt="Boost 2 Animation" width="25" height="25" />
            </div>
            <p>Watch Ad (coming soon)</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeTab;
