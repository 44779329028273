import React, { useState, useEffect } from 'react';
import '../css.components/Notifications.css';
import not from '../../public/not.gif';

const NotificationsList = ({ notifications, onClose, onClaim }) => {
  // Prevent scrolling behind the modal
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
    };
  }, []);

  return (
    <div className="notifications-fullscreen">
      <div className="notifications-header">
        <h2>Notifications</h2>
        <button className="back-button" onClick={onClose}>
          Back
        </button>
      </div>
      <div className="notifications-list">
        {notifications
          .filter((notif) => !notif.claimed)
          .map((notif) => (
            <div key={notif.id} className="notification-item">
              <div className="notification-content">
                <div className="notification-icon-emoji">🎉</div>
                <div className="notification-details">
                  <p className="notification-message">{notif.message}</p>
                  <p className="notification-bonus">
                    +{notif.bonus_ewi?.toLocaleString()} $EWI
                    <br />
                    +{notif.bonus_ewe?.toLocaleString()} $EWE
                  </p>
                </div>
                <button
                  className="claim-button"
                  onClick={() => onClaim(notif.referral_user_id)}
                >
                  Claim Reward
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const NotificationIcon = ({ userData, onNotificationsClaimed }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // ensures we only fetch once

  /**
   * 1) Extract session_id from userData
   */
  const sessionId = userData?.session_id;
  //console.error('[NotificationIcon1] Notifications error:',sessionId)
  /**
   * 2) Fetch notifications => GET /notifications?session_id=XXX
   */
  const fetchNotifications = async () => {
    if (!sessionId) return;
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/notifications?session_id=${sessionId}`;
      const res = await fetch(url, { method: 'GET' });
      const data = await res.json();
      if (!res.ok) {
        console.error('[NotificationIcon] fetchNotifications error:', res.status, data);
        return;
      }
      if (data.notifications) {
        setNotifications(data.notifications);
      } else {
        console.warn('[NotificationIcon] No notifications in response =>', data);
      }
    } catch (err) {
      console.error('[NotificationIcon] fetchNotifications exception:', err);
    }
  };

  // 3) Fetch notifications once per session
  useEffect(() => {
    if (!hasFetched && userData && sessionId) {
      fetchNotifications();
      setHasFetched(true);
    }
  }, [userData, sessionId, hasFetched]);

  /**
   * 4) Claim reward => POST /claim_bonus => { session_id, referral_user_id }
   */
  const handleClaimReward = async (referralUserId) => {
    if (!sessionId) return;

    try {
      const body = {
        session_id: sessionId,
        referral_user_id: referralUserId,
      };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/claim_bonus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      const result = await res.json();

      if (!res.ok) {
        console.error('[NotificationIcon] claimReward error =>', res.status, result);
        return;
      }
      console.log('[NotificationIcon] claimReward success =>', result);

      // Mark notification claimed in local state
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.referral_user_id === referralUserId
            ? { ...notif, claimed: true }
            : notif
        )
      );

      // Let parent refresh or handle post-claim logic
      onNotificationsClaimed(referralUserId, result.bonus_ewi, result.bonus_ewe);
    } catch (err) {
      console.error('[NotificationIcon] claimReward exception =>', err);
    }
  };

  // 5) Clicking the icon => show the notifications overlay
  const handleClick = (e) => {
    e.preventDefault();
    setShowNotifications(true);
  };

  // 6) Count unclaimed => if 0, hide the icon
  const unclaimedCount = notifications.filter((n) => !n.claimed).length;

  return (
    <>
      {unclaimedCount > 0 && (
        <button
          className="notification-icon-button"
          onClick={handleClick}
        >
          <span className="notification-badge">{unclaimedCount}</span>
          <div className="notification-icon-emoji">
            <img src={not} alt="Notification Icon" className="notification-icon" />
          </div>
        </button>
      )}
      {showNotifications && (
        <NotificationsList
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          onClaim={handleClaimReward}
        />
      )}
    </>
  );
};

export default NotificationIcon;
