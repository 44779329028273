// Ewe.js
import React, { useState, useEffect, useRef } from 'react';
import UniverseData from '../../EWI/src/UniverseData';
import Score from './comp/Score';
import FarmButton from './comp/FarmButton';
import CircularProgressBar from './comp/CircularProgressBar';
import Background from './comp/Background';
import './css/EWE.css';

const DEBUG = true;

// Helper to parse a datetime string that is missing the 'Z' offset, forcing UTC
function parseAsUTC(str) {
  if (typeof str !== 'string') return null;
  // If there's no trailing 'Z' or +/- offset, add 'Z' to force UTC
  if (!/Z$/.test(str) && !/[+-]\d\d:\d\d$/.test(str)) {
    return new Date(str + 'Z').getTime();
  }
  return new Date(str).getTime();
}

function Ewe() {
  const log = (msg, data) => DEBUG && console.log(`[EWE Debug] ${msg}`, data);

  // -----------------------------------------------
  // 0) Additional states for fetching from server
  // -----------------------------------------------
  const [gameState, setGameState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchedRef = useRef(false);

  // -----------------------------------------------
  // 1) Once mounted => fetch game_state by session_id
  // -----------------------------------------------
  useEffect(() => {
    if (fetchedRef.current) return;
    fetchedRef.current = true;

    // Try to read session_id from UniverseData first
    let sessionId = UniverseData.getCurrentData()?.session_id;
    // If not found, fallback to URL parameter (optional)
    if (!sessionId) {
      const urlParams = new URLSearchParams(window.location.search);
      sessionId = urlParams.get('session_id');
    }

    if (!sessionId) {
      console.warn('[EWE] No session_id => skipping fetch');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/game_state?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error fetching game_state: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Initialize UniverseData
        UniverseData.initialize(data);
        setGameState(data);

        // Update local EWE states
        const eweData = data.ewe || {};
        setTokens(eweData.tokens || 0);
        setFarmed(eweData.farmedTokens || 0);
        setIsFarming(eweData.isFarming || false);

        if (eweData.startTime) {
          const ms = parseAsUTC(eweData.startTime);
          setStartTime(isNaN(ms) ? null : ms);
        }
      })
      .catch((err) => {
        console.error('[EWE] /game_state fetch error:', err);
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // -----------------------------------------------
  // 2) Local states (EWE logic)
  // -----------------------------------------------
  const [tokens, setTokens] = useState(0);
  const [farmed, setFarmed] = useState(0);
  const [isFarming, setIsFarming] = useState(false);
  const [startTime, setStartTime] = useState(null);

  // Constants
  const maxTokens = 0.128;
  const farmDuration = 12 * 3600; // 12 hours in seconds

  // -----------------------------------------------
  // 3) Update DB / UniverseData => calls syncActions
  // -----------------------------------------------
  const syncEweData = (newData) => {
    UniverseData.updateEweData(newData);
    // Now push changes => UniverseData automatically uses session_id
    UniverseData.syncActions();
  };

  // -----------------------------------------------
  // 4) On mount => handle any offline progress
  // -----------------------------------------------
  useEffect(() => {
    if (isLoading || error) return;

    if (isFarming && startTime) {
      const now = Date.now();
      const elapsed = (now - startTime) / 1000;

      if (elapsed >= farmDuration) {
        setFarmed(maxTokens);
        setIsFarming(false);
        syncEweData({
          tokens,
          farmedTokens: maxTokens,
          isFarming: false,
          startTime: null,
        });
      } else {
        setFarmed((elapsed / farmDuration) * maxTokens);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  // -----------------------------------------------
  // 5) While farming => update progress every second
  // -----------------------------------------------
  useEffect(() => {
    let interval = null;
    if (!isLoading && !error && isFarming && startTime) {
      interval = setInterval(() => {
        const now = Date.now();
        const elapsed = (now - startTime) / 1000;

        if (elapsed >= farmDuration) {
          setFarmed(maxTokens);
          setIsFarming(false);
          clearInterval(interval);
          syncEweData({
            tokens,
            farmedTokens: maxTokens,
            isFarming: false,
            startTime: null,
          });
        } else {
          setFarmed((elapsed / farmDuration) * maxTokens);
        }
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [isFarming, startTime, tokens, isLoading, error]);

  // -----------------------------------------------
  // 6) startFarming => sets isFarming, zero farmed, startTime=now
  // -----------------------------------------------
  const startFarming = () => {
    if (!isFarming) {
      const now = Date.now();
      log('startFarming => user clicked =>', new Date(now).toISOString());

      syncEweData({
        tokens,
        farmedTokens: 0,
        isFarming: true,
        startTime: now,
      });

      setIsFarming(true);
      setFarmed(0);
      setStartTime(now);
    }
  };

  // -----------------------------------------------
  // 7) collect => if farmed >= max => add to tokens
  // -----------------------------------------------
  const collectTokens = () => {
    if (farmed >= maxTokens) {
      const newTotal = +(tokens + farmed).toFixed(3);
      log('collect =>', { old: tokens, farmed, newTotal });

      syncEweData({
        tokens: newTotal,
        farmedTokens: 0,
        isFarming: false,
        startTime: null,
      });

      setTokens(newTotal);
      setFarmed(0);
      setIsFarming(false);
      setStartTime(null);
    }
  };

  // -----------------------------------------------
  // 8) Single button => if done => collect, else => start
  // -----------------------------------------------
  const handleButtonClick = () => {
    if (!isFarming && farmed >= maxTokens) {
      collectTokens();
    } else if (!isFarming) {
      startFarming();
    }
  };

  // -----------------------------------------------
  // 9) Render states (loading, error, or the UI)
  // -----------------------------------------------
  if (isLoading) {
    return <div style={{ color: '#fff', padding: '20px' }}>Loading EWE...</div>;
  }

  if (error) {
    return (
      <div style={{ color: 'red', padding: '20px' }}>
        Error loading EWE data: {error}
      </div>
    );
  }

  if (!gameState) {
    return (
      <div style={{ color: '#fff', padding: '20px' }}>
        No EWE data available.
      </div>
    );
  }

  // -----------------------------------------------
  // 10) Final UI
  // -----------------------------------------------
  return (
    <div className="App1">
      <Background />
      <header className="header">
        <Score tokens={tokens} />
      </header>
      <div className="content">
        <CircularProgressBar value={farmed} maxValue={maxTokens} />
        <FarmButton
          isFarming={isFarming}
          farmedTokens={farmed}
          maxTokens={maxTokens}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
}

export default Ewe;
