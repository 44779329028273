import React from 'react';
import '../comp.css/FarmButton.css';

function FarmButton({ isFarming, farmedTokens, maxTokens, onClick }) {
  const getButtonText = () => {
    if (isFarming) {
      return `${farmedTokens.toFixed(3)}`;
    } else if (farmedTokens >= maxTokens) {
      return `CLAIM: ${farmedTokens.toFixed(3)} EWE`;
    } else {
      return 'START FARMING';
    }
  };

  const getButtonClass = () => {
    let baseClass = "FarmBtn";
    if (farmedTokens >= maxTokens) {
      baseClass += " ready-to-claim";
    }
    return baseClass;
  };

  return (
    <button
      className={getButtonClass()}
      onClick={onClick}
      disabled={isFarming && farmedTokens < maxTokens}
    >
      {getButtonText()}
    </button>
  );
}

export default FarmButton;
