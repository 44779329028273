import React, { useEffect, useRef } from 'react';
import '../css.components/bg.css';
import heart from '../../public/heart.png'
const Background = () => {
  const sectionRef = useRef(null);

  const renderBlocks = () => {
    const section = sectionRef.current;
    const fragment = document.createDocumentFragment();

    for (let i = 0; i < 60; i++) {
      const span = document.createElement('span');
      span.classList.add('sp1');
      Object.assign(span.style, {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        width: 'calc(15vw)',
        height: 'calc(15vw)',
        zIndex: '2',
        transition: 'transform 0.1s ease', // плавное преобразование
      });

      // Добавляем изображение снежинки внутри блока
      const img = document.createElement('img');
       img.src = heart // Путь к изображению
      // img.alt = 'Snowflake';
      img.style.width = '4vw';
      img.style.height = '4vw';
      img.style.position = 'absolute';
      img.style.zIndex = '3';

      span.appendChild(img);
      fragment.appendChild(span);
    }

    section.appendChild(fragment);
  };

  useEffect(() => {
    renderBlocks();
  }, []);

  return <section className="bg1" ref={sectionRef}></section>;
};

export default Background;
