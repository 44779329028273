import React, { useState, useEffect } from 'react';
import '../css.components/ReferralShare.css';

const ReferralShare = () => {
  const [referralLink, setReferralLink] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [stats, setStats] = useState({ referrals_count: 0 });
  const [didFetch, setDidFetch] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchReferralData = async () => {
      try {
        if (ignore || didFetch) return;

        /**
         * EXTRACT session_id (from query or other global)
         * If you have it in the URL, parse it:
         *    const urlParams = new URLSearchParams(window.location.search);
         *    const sessionId = urlParams.get('session_id');
         *
         * If you have it in Telegram's initData, or from your React context, adapt as needed.
         */
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        if (!sessionId) {
          console.warn('[ReferralShare] No session_id => cannot fetch referral data');
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_referral_data?session_id=${sessionId}`
        );

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        if (!ignore) {
          setReferralLink(data.referral_link || '');
          setStats({ referrals_count: data.referrals_count || 0 });
          setReferrals(data.referrals || []);
        }
      } catch (error) {
        console.error('Error fetching referral data:', error);
      } finally {
        if (!ignore) {
          setDidFetch(true);
        }
      }
    };

    if (!didFetch) {
      fetchReferralData();
    }

    return () => {
      ignore = true;
    };
  }, [didFetch]);

  const handleShare = () => {
    const shareText = encodeURIComponent('Join our awesome game!');
    const shareUrl = encodeURIComponent(referralLink);
    const tgLink = `https://t.me/share/url?text=${shareText}&url=${shareUrl}`;
    if (window.Telegram?.WebApp?.openTelegramLink) {
      window.Telegram.WebApp.openTelegramLink(tgLink);
    } else {
      window.open(tgLink, '_blank');
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        const messageElement = document.createElement('div');
        messageElement.textContent = 'Copied!';
        // style it...
        document.body.appendChild(messageElement);
        setTimeout(() => messageElement.remove(), 1000);
      })
      .catch((error) => console.error('Copy failed:', error));
  };

  return (
    <div className="referral-page">
      <div className="referral-content">
        {/* Referral Link Section */}
        <div className="link-section">
          <h2>Your Referral Link</h2>
          <div className="link-container">
            <input type="text" value={referralLink} readOnly />
            <button onClick={handleCopy}>Copy</button>
          </div>
        </div>

        {/* Rewards Section (example static content) */}
        <div className="rewards-section">
          <div className="reward-box">
            <h3>You Get</h3>
            <p>50,000 EWI</p>
            <p>1.792 EWE</p>
          </div>
          <div className="reward-box">
            <h3>Friend Gets</h3>
            <p>25,000 EWI</p>
            <p>0.896 EWE</p>
          </div>
        </div>

        {/* Referrals Section */}
        <div className="referrals-section">
          <h2>Your Referrals ({stats.referrals_count})</h2>
          <div className="referrals-list">
            {referrals.map((referral, index) => (
              <div key={index} className="referral-item">
                <span className="referral-username">
                  @{referral.username || 'unknown'}
                </span>
                <span className="referral-date">
                  {referral.joined_date
                    ? new Date(referral.joined_date).toLocaleDateString()
                    : 'N/A'}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Share Button */}
        <button className="share-button" onClick={handleShare}>
          Share With Friends
        </button>
      </div>
    </div>
  );
};

export default ReferralShare;
