// Leaderboard.js
import React, { useEffect, useState } from 'react';
import '../css.components/Leaderboard.css';

function Leaderboard() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  // Example fetch from your server's /leaderboard
  useEffect(() => {
    fetch('/leaderboard')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch leaderboard');
        }
        return res.json();
      })
      .then((data) => {
        // data = [ { telegram_id, total_donated } ...]
        setUsers(data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  // top row of 7 donors
  const top7 = users.slice(0, 7);
  // the rest go in the "Top Holders"
  const holders = users.slice(7);

  const rankIcon = (index) => {
    // Return index + 1. You can customize for 1,2,3 with special icons.
    return index + 1;
  };

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-header">
        {/* Banner or title */}
        <h2>Leaderboard</h2>
      </div>

      {/* Banner Area */}
      <div className="leaderboard-banner">
        <h3>Some Gaming Event Is Coming</h3>
      </div>

      {/* top row */}
      <div className="top-donors-row">
        {top7.map((u, i) => (
          <div key={u.telegram_id} className="top-donor">
            <div className="rank-badge">{rankIcon(i)}</div>
            <div className="circle-avatar">
              <img src="/images/avatar-placeholder.png" alt="avatar" />
            </div>
            <p className="donated-amount">{u.total_donated.toFixed(2)} TON</p>
          </div>
        ))}
      </div>

      {/* wallet/reward area from screenshot (optional) */}
      <div className="wallet-reward-row">
        <div className="wallet-display">
          <span className="ton-amount">0.00</span>
          <button className="connect-wallet">Connect wallet</button>
        </div>
        <button className="unavailable-btn">Unavailable</button>
        <button className="reward-btn">Reward</button>
      </div>

      {/* top holders */}
      <section className="top-holders">
        <h3>Top Holders</h3>
        {error && <p className="error-text">{error}</p>}
        <ul className="holders-list">
          {holders.map((u, idx) => (
            <li key={u.telegram_id}>
              <span className="holder-rank">{7 + idx + 1}</span>
              <div className="holder-info">
                <p>User {u.telegram_id}</p>
                <span>{u.total_donated.toFixed(2)} TON</span>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Leaderboard;
